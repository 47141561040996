// Fonts
$font-stack: Arial, Helvetica, sans-serif;
$font-stack-titles: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;

// Need to change names for readability after making all changes
$white:     #fff;
$shadow:    #eee;
$medium:    #ccc;
$light:     #aaa;
$grey:      #888;
$darker:    #666;
$dark:      #222;
$black:     #000;
$highlight: #04d;
$yellow:    #fa0;
