.form-group {
	margin-bottom: 1rem;
}

form {
	margin: 0;
}
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}
legend {
	border: 0;
	padding: 0;
}
label {
	cursor: pointer;
	font-weight: bold;
	display: block;
	margin-bottom: 5px;
}
textarea {
  min-height: 10rem;
  overflow: auto;
  resize: vertical;
	background: $white;
}

button[disabled] {
  opacity: .5;
}
