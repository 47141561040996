/* Syntax Highlighting for Code Snippets
*/

pre.highlight { padding: 16px; }

/** Light style Generated using `rougify style github > path/to/file.css`
*/
.highlight, .highlight .w { color: #24292f; background-color: #f6f8fa; }
.highlight .k, .highlight .kd, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kt, .highlight .kv { color: #cf222e; }
.highlight .gr { color: #f6f8fa; }
.highlight .gd { color: #82071e; background-color: #ffebe9; }
.highlight .nb { color: #953800; }
.highlight .nc { color: #953800; }
.highlight .no { color: #953800; }
.highlight .nn { color: #953800; }
.highlight .sr { color: #116329; }
.highlight .na { color: #116329; }
.highlight .nt { color: #116329; }
.highlight .gi { color: #116329; background-color: #dafbe1; }
.highlight .kc { color: #0550ae; }
.highlight .l, .highlight .ld, .highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx { color: #0550ae; }
.highlight .sb { color: #0550ae; }
.highlight .bp { color: #0550ae; }
.highlight .ne { color: #0550ae; }
.highlight .nl { color: #0550ae; }
.highlight .py { color: #0550ae; }
.highlight .nv, .highlight .vc, .highlight .vg, .highlight .vi, .highlight .vm { color: #0550ae; }
.highlight .o, .highlight .ow { color: #0550ae; }
.highlight .gh { color: #0550ae; font-weight: bold; }
.highlight .gu { color: #0550ae; font-weight: bold; }
.highlight .s, .highlight .sa, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .se, .highlight .sh, .highlight .sx, .highlight .s1, .highlight .ss { color: #0a3069; }
.highlight .nd { color: #8250df; }
.highlight .nf, .highlight .fm { color: #8250df; }
.highlight .err { color: #f6f8fa; background-color: #82071e; }
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cp, .highlight .cpf, .highlight .c1, .highlight .cs { color: #6e7781; }
.highlight .gl { color: #6e7781; }
.highlight .gt { color: #6e7781; }
.highlight .ni { color: #24292f; }
.highlight .si { color: #24292f; }
.highlight .ge { color: #24292f; font-style: italic; }
.highlight .gs { color: #24292f; font-weight: bold; }

/** Dark style Generated using `rougify style github.dark > path/to/file.css`
*/
[data-bs-theme=dark] .highlight, [data-bs-theme=dark] .highlight .w { color: #c9d1d9; background-color: #161b22; }
[data-bs-theme=dark] .highlight .k, [data-bs-theme=dark] .highlight .kd, [data-bs-theme=dark] .highlight .kn, [data-bs-theme=dark] .highlight .kp, [data-bs-theme=dark] .highlight .kr, [data-bs-theme=dark] .highlight .kt, [data-bs-theme=dark] .highlight .kv { color: #ff7b72; }
[data-bs-theme=dark] .highlight .gr { color: #f0f6fc; }
[data-bs-theme=dark] .highlight .gd { color: #ffdcd7; background-color: #67060c; }
[data-bs-theme=dark] .highlight .nb { color: #ffa657; }
[data-bs-theme=dark] .highlight .nc { color: #ffa657; }
[data-bs-theme=dark] .highlight .no { color: #ffa657; }
[data-bs-theme=dark] .highlight .nn { color: #ffa657; }
[data-bs-theme=dark] .highlight .sr { color: #7ee787; }
[data-bs-theme=dark] .highlight .na { color: #7ee787; }
[data-bs-theme=dark] .highlight .nt { color: #7ee787; }
[data-bs-theme=dark] .highlight .gi { color: #aff5b4; background-color: #033a16; }
[data-bs-theme=dark] .highlight .kc { color: #79c0ff; }
[data-bs-theme=dark] .highlight .l, [data-bs-theme=dark] .highlight .ld, [data-bs-theme=dark] .highlight .m, [data-bs-theme=dark] .highlight .mb, [data-bs-theme=dark] .highlight .mf, [data-bs-theme=dark] .highlight .mh, [data-bs-theme=dark] .highlight .mi, [data-bs-theme=dark] .highlight .il, [data-bs-theme=dark] .highlight .mo, [data-bs-theme=dark] .highlight .mx { color: #79c0ff; }
[data-bs-theme=dark] .highlight .sb { color: #79c0ff; }
[data-bs-theme=dark] .highlight .bp { color: #79c0ff; }
[data-bs-theme=dark] .highlight .ne { color: #79c0ff; }
[data-bs-theme=dark] .highlight .nl { color: #79c0ff; }
[data-bs-theme=dark] .highlight .py { color: #79c0ff; }
[data-bs-theme=dark] .highlight .nv, [data-bs-theme=dark] .highlight .vc, [data-bs-theme=dark] .highlight .vg, [data-bs-theme=dark] .highlight .vi, [data-bs-theme=dark] .highlight .vm { color: #79c0ff; }
[data-bs-theme=dark] .highlight .o, [data-bs-theme=dark] .highlight .ow { color: #79c0ff; }
[data-bs-theme=dark] .highlight .gh { color: #1f6feb; font-weight: bold; }
[data-bs-theme=dark] .highlight .gu { color: #1f6feb; font-weight: bold; }
[data-bs-theme=dark] .highlight .s, [data-bs-theme=dark] .highlight .sa, [data-bs-theme=dark] .highlight .sc, [data-bs-theme=dark] .highlight .dl, [data-bs-theme=dark] .highlight .sd, [data-bs-theme=dark] .highlight .s2, [data-bs-theme=dark] .highlight .se, [data-bs-theme=dark] .highlight .sh, [data-bs-theme=dark] .highlight .sx, [data-bs-theme=dark] .highlight .s1, [data-bs-theme=dark] .highlight .ss { color: #a5d6ff; }
[data-bs-theme=dark] .highlight .nd { color: #d2a8ff; }
[data-bs-theme=dark] .highlight .nf, [data-bs-theme=dark] .highlight .fm { color: #d2a8ff; }
[data-bs-theme=dark] .highlight .err { color: #f0f6fc; background-color: #8e1519; }
[data-bs-theme=dark] .highlight .c, [data-bs-theme=dark] .highlight .ch, [data-bs-theme=dark] .highlight .cd, [data-bs-theme=dark] .highlight .cm, [data-bs-theme=dark] .highlight .cp, [data-bs-theme=dark] .highlight .cpf, [data-bs-theme=dark] .highlight .c1, [data-bs-theme=dark] .highlight .cs { color: #8b949e; }
[data-bs-theme=dark] .highlight .gl { color: #8b949e; }
[data-bs-theme=dark] .highlight .gt { color: #8b949e; }
[data-bs-theme=dark] .highlight .ni { color: #c9d1d9; }
[data-bs-theme=dark] .highlight .si { color: #c9d1d9; }
[data-bs-theme=dark] .highlight .ge { color: #c9d1d9; font-style: italic; }
[data-bs-theme=dark] .highlight .gs { color: #c9d1d9; font-weight: bold; }
