$fa-font-path: "/fonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

$enable-negative-margins: true;

$grid-breakpoints: (
  xs: 0,
  sm: 342px,
  md: 576px,
  lg: 768px,
  xl: 992px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px
);

$utilities: (
  // Make width utility responsive
  "width": (
    property: width,
    responsive: true,
    class: w,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
);
@import "~bootstrap/scss/bootstrap.scss";

@import "variables.scss";
@import "skiplinks.scss";
@import "navbar.scss";
@import "forms.scss";
@import "main.scss";
@import "syntax-highlighting.css";
