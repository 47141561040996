body {
  position: relative;
}
.skiplinks {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
  z-index: 1050;
  li {
    list-style: none none;
  }
  .visually-hidden-focusable {
    display: inline-block;
    color: $black;
    background: $yellow;
    padding: 1rem;
    text-decoration: none;
  }
}
