// Custom font for headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// Full-height page with bottom footer
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  main {
    flex-grow: 1;
  }
}
// Prevent main content from appearing under the navbar
.fixed-top + main  {
  padding-top: 6rem;
  scroll-margin-top: 6rem;
}
:target,
:focus {
  scroll-margin-top: 6rem;
}

// Responsive images
main img {
  height: auto;
  max-width: 100%;
}
// Center images in otherwise empty paragraphs (generated by MarkDown conversion)
p img:only-child {
  display: block;
  margin: 0 auto;
}
// Cover images on single page
.cover-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 2rem auto;
  border-radius: .5em;
  box-shadow: $box-shadow;
  // Float cover image to the right on large screens
  @include media-breakpoint-up(lg) {
    float: right;
    max-width: 50%;
    margin: 0 0 2em 2em;
  }
}
.content {
  clear: both;
}

blockquote,
.comment {
  padding: 1em 3em 1em 1em;
  min-height: 2em;
  background: var(--bs-primary-bg-subtle);
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  position: relative;
  &::before {
    position: absolute;
    content: '\2033';
    color: $secondary;
    top: -1.2rem;
    right: 0.4rem;
    font-size: 5em;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.task-list {
  list-style: none;
  .task-list-item-checkbox {
    margin-left: -1em;
    margin-right: .5em;
  }
}

// Allow using .list-group-item-action inside .list-group-item
.list-group-item {
  .list-group-item-action:last-child {
    margin: ($list-group-item-padding-y * -1)
      ($list-group-item-padding-x * -1);
    padding: $list-group-item-padding-y $list-group-item-padding-x;
    display: block;
    width: auto;
  }
}

@include media-breakpoint-up(sm) {
  .content {
    ul, ol {
      &:not(.list-group, .list-group-flush) {
        margin-left: -2em;
      }
    }
  }
}
