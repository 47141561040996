.navbar-light .navbar-toggler-transformicon {
  background: currentColor;
}
/* Animate burger icon - Code adapted from https://www.sarasoueidan.com/demos/navicon-transformicons/ */
.navbar-toggler-transformicon {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 1rem;
  height: 2px;
  background: currentColor;
  transition: 0.3s;
  position: relative;
  top: -.35em;
}
.navbar-toggler-transformicon:before,
.navbar-toggler-transformicon:after {
  display: inline-block;
  width: inherit;
  height: inherit;
  background: currentColor;
  transition: 0.3s;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: 0.2rem center;
}
.navbar-toggler-transformicon:before {
  top: .45rem;
}
.navbar-toggler-transformicon:after {
  top: -.45rem;
}

[aria-expanded='true'] .navbar-toggler-transformicon {
  background: transparent;
}
[aria-expanded='true'] .navbar-toggler-transformicon:before,
[aria-expanded='true'] .navbar-toggler-transformicon:after {
  transform-origin: 50% 50%;
  top: 0;
}
[aria-expanded='true'] .navbar-toggler-transformicon:before {
  transform: rotate3d(0, 0, 1, 45deg);
}
[aria-expanded='true'] .navbar-toggler-transformicon:after {
  transform: rotate3d(0, 0, 1, -45deg);
}


/* Style navbar-brand hover */
.navbar-brand:focus,
.navbar-brand:hover {
  color: #007bff;
}
@include media-breakpoint-up(md) {
  .nav-link {
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      transition: height .25s ease-in-out;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
    }
    &:hover::before, &:focus::before {
      background: #aaa;
      height: 3px;
    }
    &.active::before {
      background: #007bff;
      height: 3px;
    }
  }
  #bd-theme {
    padding-top: 13px;
    padding-bottom: 10px;
    &::before {
      display: none;
    }
  }
}
